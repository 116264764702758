import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  AddDepotField,
  CloseButton,
  GreenButton,
  GreyButton,
  HeadPart,
  AddButton,
  ModalButtons,
  ModalContent,
  ModalContentData,
  ModalHeader,
  TableContent,
  DeleteButton,
  RedButton,
  SelectInput,
} from './styled';
import { t } from 'i18next';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, has } from 'lodash';
import { addDepot, deleteDepot, editDepot, getDepots } from '../../redux/depot';
import { CloseIcon, DeleteIcon, EditIcon } from '../../assets/icon';
import { Form, Formik, useFormik } from 'formik';
import AddressInput from '../AddressInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const DepotsListComponent = () => {
  const { depots, addDepotDetails, deleteDepotDetails, editDepotDetails } =
    useSelector((state) => state.depot);
  const dispatch = useDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDepot, setSelectedDepot] = useState(null);

  const initialValues = {
    name: '',
    depot_type: 'Own',
    lat: '',
    lon: '',
    address: '',
    post_code: '',
    country: '',
    city: '',
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(t('General.NameRequired')),
      address: Yup.string().required(t('General.AddressRequired')),
    }),
    onSubmit: (values, { resetForm }) => {
      if (has(values, 'id')) {
        dispatch(editDepot(values));
      } else {
        dispatch(addDepot(values));
      }
      resetForm();
    },
  });
  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    resetForm();
  };
  const handleEditModal = (depot) => {
    setValues(depot);
    setIsEditModalOpen(true);
  };
  const handleDeleteModal = (depot) => {
    setSelectedDepot(depot);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteConfirm = () => {
    dispatch(deleteDepot(selectedDepot?.id));
    setIsDeleteModalOpen(false);
  };
  useEffect(() => {
    dispatch(getDepots());
    if (addDepotDetails) {
      setIsAddModalOpen(false);
    }
    if (deleteDepotDetails) {
      setIsDeleteModalOpen(false);
    }
    if (editDepotDetails) {
      setIsEditModalOpen(false);
    }
  }, [addDepotDetails, deleteDepotDetails, editDepotDetails]);

  return (
    <>
      <HeadPart>
        <Typography component="h2">{t('Profile.ListDepots')}</Typography>
        <AddButton>
  <Button
    onClick={() => setIsAddModalOpen(true)}
    variant="contained"
    startIcon={<AddOutlinedIcon />}
  >
    {t('Profile.AddDepot')}
  </Button>
</AddButton>
      </HeadPart>
      <TableContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('Profile.Name')}</TableCell>
                <TableCell>{t('Profile.DepotType')}</TableCell>
                <TableCell>{t('Profile.City')}</TableCell>
                <TableCell>{t('Profile.Address')}</TableCell>
                <TableCell>{t('Profile.Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(depots) ? (
                depots.map((depot, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{depot.name}</TableCell>
                      <TableCell>{t(`Profile.${depot.depot_type}`)}</TableCell>
                      <TableCell>{depot.city}</TableCell>
                      <TableCell>{depot.address}</TableCell>
                      <TableCell>
                        <ButtonGroup>
                          <DeleteButton onClick={() => handleEditModal(depot)}>
                            <EditIcon />
                          </DeleteButton>
                          <DeleteButton
                            onClick={() => handleDeleteModal(depot)}
                          >
                            <DeleteIcon />
                          </DeleteButton>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    {t('Profile.NoDepotsFound')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContent>
      {/* Start Add Depot Modal */}
      <Modal
        open={isAddModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-depot"
        aria-describedby="add-depot-body"
      >
        <ModalContent>
          <ModalHeader>
            <Typography id="add-depot" variant="h3">
              {t('Profile.AddDepot')}
            </Typography>
            <CloseButton onClick={handleCloseModal}>
              <CloseIcon />
            </CloseButton>
          </ModalHeader>
          <ModalContentData id="add-depot-body">
            <Formik>
              <Form noValidate autoComplete="off">
                <AddDepotField>
                  <InputLabel>{t('Profile.Name')}</InputLabel>
                  <TextField
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </AddDepotField>
                <AddDepotField>
                  <InputLabel>{t('Profile.DepotType')}</InputLabel>
                  <SelectInput>
                    <Select
                      name="depot_type"
                      value={values.depot_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.depot_type && errors.depot_type)}
                    >
                      <MenuItem value="Own">{t('Profile.Own')}</MenuItem>
                      <MenuItem value="Supplier">
                        {t('Profile.Supplier')}
                      </MenuItem>
                    </Select>
                  </SelectInput>
                </AddDepotField>
                <AddDepotField>
                  <InputLabel>{t('Profile.Address')}</InputLabel>
                  <AddressInput
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </AddDepotField>
                <ModalButtons>
                  <GreenButton>
                    <Button onClick={handleSubmit} variant="contained">
                      {t('Profile.Add')}
                    </Button>
                  </GreenButton>
                  <GreyButton>
                    <Button onClick={handleCloseModal} variant="contained">
                      {t('Profile.Cancel')}
                    </Button>
                  </GreyButton>
                </ModalButtons>
              </Form>
            </Formik>
          </ModalContentData>
        </ModalContent>
      </Modal>
      {/* End Add Depot Modal */}
      {/* Start Edit Depot Modal */}
      <Modal
        open={isEditModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="Edit-depot"
        aria-describedby="Edit-depot-body"
      >
        <ModalContent>
          <ModalHeader>
            <Typography id="edit-depot" variant="h3">
              {t('Profile.EditDepot')}
            </Typography>
            <CloseButton onClick={handleCloseModal}>
              <CloseIcon />
            </CloseButton>
          </ModalHeader>
          <ModalContentData id="edit-depot-body">
            <Formik>
              <Form noValidate autoComplete="off">
                <AddDepotField>
                  <InputLabel>{t('Profile.Name')}</InputLabel>
                  <TextField
                    value={values.name}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </AddDepotField>
                <AddDepotField>
                  <InputLabel>{t('Profile.DepotType')}</InputLabel>
                  <SelectInput>
                    <Select
                      name="depot_type"
                      value={values.depot_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.depot_type && errors.depot_type)}
                    >
                      <MenuItem value="Own">{t('Profile.Own')}</MenuItem>
                      <MenuItem value="Supplier">
                        {t('Profile.Supplier')}
                      </MenuItem>
                    </Select>
                  </SelectInput>
                </AddDepotField>
                <AddDepotField>
                  <InputLabel>{t('Profile.Address')}</InputLabel>
                  <AddressInput
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </AddDepotField>
                <ModalButtons>
                  <GreenButton>
                    <Button onClick={handleSubmit} variant="contained">
                      {t('Profile.Update')}
                    </Button>
                  </GreenButton>
                  <GreyButton>
                    <Button onClick={handleCloseModal} variant="contained">
                      {t('Profile.Cancel')}
                    </Button>
                  </GreyButton>
                </ModalButtons>
              </Form>
            </Formik>
          </ModalContentData>
        </ModalContent>
      </Modal>
      {/* End Edit Depot Modal */}
      {/* Start Delete Depot Dialog */}
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        aria-labelledby="delete-depot"
        aria-describedby="delete-depot-description"
      >
        <>
          <ModalContent>
            <ModalHeader>
              <Typography id="delete-depot" variant="h3">
                {t('Profile.CanelConfirm')}
              </Typography>
              <CloseButton onClick={() => setIsDeleteModalOpen(false)}>
                <CloseIcon />
              </CloseButton>
            </ModalHeader>
            <ModalContentData>
              <Typography id="delete-depot-description" variant="h5">
                {t('Profile.CancelConfirmText')}
              </Typography>
              <ModalButtons>
                <RedButton>
                  <Button onClick={handleDeleteConfirm} variant="contained">
                    {t('Profile.CancelYes')}
                  </Button>
                </RedButton>
                <GreyButton>
                  <Button
                    onClick={() => setIsDeleteModalOpen(false)}
                    variant="contained"
                  >
                    {t('Profile.CancelNo')}
                  </Button>
                </GreyButton>
              </ModalButtons>
            </ModalContentData>
          </ModalContent>
        </>
      </Modal>
      {/* End Delete Depot Dialog */}
    </>
  );
};

export default DepotsListComponent;
