import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TripBox } from './styled';
import { t } from 'i18next';
import { BlockTitle } from '../TripSummary/styled';

const CustomerInfo = ({
  firstName,
  surname,
  clientType,
  companyAddress,
  companyNumber,
  companyVatNumber,
  email,
}) => {
  return (
    <>
      <BlockTitle>{t('TripDetail.ClientData')}</BlockTitle>
      <TripBox>
        <Grid container spacing={2}>
          {firstName && (
            <Grid item sm={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.Name')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {firstName}
              </Typography>
            </Grid>
          )}
          {surname && (
            <Grid item md={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.Surname')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {surname}
              </Typography>
            </Grid>
          )}
          {clientType && (
            <Grid item md={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.ClientType')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {clientType}
              </Typography>
            </Grid>
          )}
          {companyAddress && (
            <Grid item md={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.Address')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {companyAddress}
              </Typography>
            </Grid>
          )}
          {companyNumber && (
            <Grid item md={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.Telephone')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {companyNumber}
              </Typography>
            </Grid>
          )}
          {companyVatNumber && (
            <Grid item md={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.VATNumber')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {companyVatNumber}
              </Typography>
            </Grid>
          )}
          {email && (
            <Grid item md={12} xl={6}>
              <Typography variant="body1" display="block">
                <strong>{t('TripDetail.Email')}:</strong>
              </Typography>
              <Typography
                variant="body2"
                display="block"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {email}
              </Typography>
            </Grid>
          )}
        </Grid>
      </TripBox>
    </>
  );
};

export default CustomerInfo;
