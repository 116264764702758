import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, CircularProgress, Card } from '@mui/material';
import { ConfirmButton, BlockTitle, BackButton } from './styled';
import { ArrowBack } from '@mui/icons-material';
import { t } from 'i18next';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useNavigate, useParams } from 'react-router-dom';
import Chat from '../../components/Chat';
import TripStopsSummaryWithData from '../../components/TripSummary/dataWrapper';
import { callGetAPI } from '../../services/auth';
import { callPostAPI } from '../../services/auth';
import NextBestActionComponent from '../../components/NextBestActionComponent';

const TripRequestConversation = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { tripRequestCode } = useParams();
  const [tripData, setTripData] = useState({});
  const updateTripData = (setTripData, tripRequestCode) => {
    getTripRequestData({ tripRequestCode }).then((response) => {
      console.log(response);
      setTripData(response?.data?.data);
    });
  };

  useEffect(
    () => updateTripData(setTripData, tripRequestCode),
    [setTripData, tripRequestCode],
  );

  const handleStatusChange = async () => {
    const payload = {
      trip_request_id: tripData.request.id,
      status: 'confirmed',
    };
    // TODO error handling
    setIsLoading(true);
    await updateRequestStatus(payload);
    await updateTripData(setTripData, tripRequestCode);
    setIsLoading(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BackButton>
          <Button
            variant="contained"
            onClick={() => {
              const currentPath = window.location.pathname;
              const newPath = currentPath.replace(/\/conversation$/, ''); // Remove '/conversation' if present
              navigate(newPath);
            }}
            sx={{ marginX: '14px' }}>
            <ArrowBack />
            {t('PriceCalc.Button.Back')}
          </Button>
          </BackButton>
        </Grid>
        {/* {tripData?.request?.status === 'accepted' ? (
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            isLoading={isLoading}>
            <ConfirmButton>
              <Button
                onClick={handleStatusChange}
                variant="contained"
                sx={{ marginX: '14px', position: 'relative' }}
                disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                ) : (
                  t('Conferma disponibilità')
                )}
              </Button>
            </ConfirmButton>
          </Grid>
        ) : null} */}
        <Grid item xs={12} md={6} lg={6}>
          <NextBestActionComponent status={tripData?.request?.status} />

          <TripStopsSummaryWithData tripData={tripData} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box>
            <BlockTitle>{'Messaggi'}</BlockTitle>
          </Box>
          <Chat />
        </Grid>
      </Grid>
    </Box>
  );
};

const getTripRequestData = async ({ tripRequestCode }) => {
  return await callGetAPI({
    route: `/trip-requests/${tripRequestCode}/details`,
  });
};

const updateRequestStatus = async (params) => {
  return await callPostAPI({
    route: 'update-trip-request-status',
    body: params,
  });
};

export default TripRequestConversation;
