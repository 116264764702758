import styled from '@emotion/styled';

export const MainPricing = styled.div`
  display: flex;
  justify-content: space-between;  // Keeps space between items
  margin: 0 0 50px 0;
  flex-wrap: wrap;  // Allows items to wrap in smaller screens
`;

export const PrcingBox = styled.div`
  border-radius: 10px;
  border: 1px solid #cbd5e1;
  background: #fff;
  width: calc(33.33% - 20px);  // Adjusts width for three boxes with proper gaps
  max-width: 362px;  // Maximum width limit
  text-align: left;
  padding: 30px;
  margin: 0 10px 20px;  // Adds spacing around each box

  @media screen and (max-width: 1600px) {
    width: calc(25% - 20px); // Two boxes per row on medium screens
    max-width: 280px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;  // One box per row on small screens
    margin: 0 0 20px;  // Adjust margin for vertical stacking
  }

  span {
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    color: #1e293b;
    margin: 0 0 15px 0;
    display: block;
    
    @media screen and (max-width: 1199px) {
      font-size: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #000;
    
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
`;
