import React from 'react';
import PricingComponent from '../../components/PricingComponent';
import EstimateComponent from '../../components/EstimateComponent';
import { Container } from '@mui/system';
import {} from './styled.js';
const RequestPage = () => {
  return (
    <>
      <Container maxWidth="xl">
        <PricingComponent />
        <EstimateComponent />
      </Container>
    </>
  );
};

export default RequestPage;
