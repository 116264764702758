import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { getArrivalTimeReturn } from '../../redux/pricecalc';
import { RightIcon, PinIcon, DeleteIcon } from '../../assets/icon';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {
  TripBox,
  BoxHeading,
  Number,
  TripFeild,
  LeftPart,
  RightArrow,
  InputBox,
  RightPart,
  DateSelect,
  TimeSelect,
  DeleteButton,
} from './styled';
import {
  addDurationToTime,
  convertTripDetails,
  roundToNearest15MinutesAfterAddingDuration,
} from '../../utils/helper';

dayjs.extend(updateLocale);
dayjs.updateLocale('it', {
  weekStart: 1,
});
const ReturnTripBoxComponent = ({ index, returnBlock, deleteStop }) => {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } =
    useFormikContext();
  const dispatch = useDispatch();
  const { arrivalTimeDetailReturn } = useSelector((state) => state.pricecalc);
  const [depatureDateTime, setDepatureDateTime] = useState(
    dayjs(values.return_stop.date).format('YYYY-MM-DD') +
      ' ' +
      dayjs(values.return_stop.time).format('HH:mm'),
  );
  const [duration, setDuration] = useState(values.return_stop['duration']);
  const handleGlobalChange = () => {
    const tripStops = convertTripDetails(values.return_stop);
    const payload = {
      transport_mode: 'truck',
      routing_mode: 'fast',
      index: -1,
      ...tripStops,
    };
    if (!isEmpty(tripStops)) {
      dispatch(getArrivalTimeReturn(payload));
    }
  };
  useEffect(() => {
    if (
      !isEmpty(arrivalTimeDetailReturn) &&
      arrivalTimeDetailReturn.index === -1
    ) {
      setDuration(arrivalTimeDetailReturn.duration);
    }
  }, [arrivalTimeDetailReturn]);
  useEffect(() => {
    // This effect will run whenever the specific field is updated
    handleGlobalChange();
  }, [values.return_stop.start, values.return_stop.end]);
  useEffect(() => {
    if (arrivalTimeDetailReturn.index === -1) {
      setFieldValue('return_stop.duration', arrivalTimeDetailReturn.duration);
      setFieldValue(
        'return_stop.arrival',
        addDurationToTime(
          dayjs(values.return_stop.date).format('YYYY-MM-DD') +
            ' ' +
            dayjs(values.return_stop.time).format('HH:mm'),
          arrivalTimeDetailReturn.duration,
        ),
      );
      setFieldValue(
        'return_stop.next_depature',
        roundToNearest15MinutesAfterAddingDuration(
          dayjs(values.return_stop.date).format('YYYY-MM-DD') +
            ' ' +
            dayjs(values.return_stop.time).format('HH:mm'),
          arrivalTimeDetailReturn.duration,
        ),
      );
    } else if (arrivalTimeDetailReturn.index != undefined) {
      setFieldValue('return_stop.duration', duration);
      setFieldValue(
        'return_stop.arrival',
        addDurationToTime(
          dayjs(values.return_stop.date).format('YYYY-MM-DD') +
            ' ' +
            dayjs(values.return_stop.time).format('HH:mm'),
          duration,
        ),
      );
      setFieldValue(
        'return_stop.next_depature',
        roundToNearest15MinutesAfterAddingDuration(
          dayjs(values.return_stop.date).format('YYYY-MM-DD') +
            ' ' +
            dayjs(values.return_stop.time).format('HH:mm'),
          duration,
        ),
      );
    }
  }, [
    duration,
    depatureDateTime,
    values.return_stop['date'],
    values.return_stop['time'],
  ]);
  useEffect(() => {
    if (
      values.trip_stops[index - 1]['duration'] &&
      dayjs(
        dayjs(values.return_stop.date).format('YYYY-MM-DD') +
          ' ' +
          dayjs(values.return_stop.time).format('HH:mm'),
      ).isBefore(dayjs(values.trip_stops[index - 1]['next_depature']))
    ) {
      setFieldValue(
        'return_stop[date]',
        dayjs(values.trip_stops[index - 1]['next_depature']),
      );
      setFieldValue(
        'return_stop[time]',
        dayjs(values.trip_stops[index - 1]['next_depature']),
      );
    }
  }, [values.trip_stops[index - 1]]);

  return (
    <TripBox>
      <BoxHeading>
        {/* <Number>
          <Typography variant="span">{index + 1}</Typography>
        </Number> */}
        <Typography variant="h3">
          {returnBlock
            ? t('PriceCalc.Return')
            : index > 0
              ? t('PriceCalc.Stop')
              : t('PriceCalc.Gone')}
        </Typography>
        <input
          type="hidden"
          name={'return_stop[sequence]'}
          value={index + 1}
        ></input>
        {!returnBlock && index > 0 ? (
          <DeleteButton onClick={deleteStop}>
            <DeleteIcon />
          </DeleteButton>
        ) : (
          ''
        )}
      </BoxHeading>
      <TripFeild>
        <LeftPart>
          <InputBox>
            <InputLabel htmlFor="return_stop.start.address">
              {t('PriceCalc.StartPoint')}
            </InputLabel>
            <TextField
              placeholder="Enter a location"
              disabled
              id="return_stop.start.address"
              name="return_stop.start.address"
              value={values.return_stop.start.address || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              
            />
          </InputBox>
          <RightArrow>
            <ChevronRightOutlinedIcon />
          </RightArrow>
          <InputBox>
            <InputLabel htmlFor="return_stop.end.address">
              {t('PriceCalc.Destination')}
            </InputLabel>
            <TextField
              placeholder="Enter a location"
              disabled
              id="return_stop.end.address"
              name="return_stop.end.address"
              value={values.return_stop.end.address || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              
            />
          </InputBox>
        </LeftPart>
        <RightPart>
          <DateSelect>
            <InputLabel htmlFor="return_stop_date">
              {t('PriceCalc.DepatureDate')}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
              <DatePicker
                disablePast
                id="return_stop_date"
                name="return_stop[date]"
                error={Boolean(
                  touched.return_stop &&
                    touched.return_stop.date &&
                    errors.return_stop &&
                    errors.return_stop.date,
                )}
                slotProps={{
                  textField: {
                    required: true,
                    helperText:
                      touched.return_stop &&
                      touched.return_stop.date &&
                      errors.return_stop &&
                      errors.return_stop.date,
                  },
                }}
                // minDate={index > 0 ? dayjs(values.trip_stops[index - 1]['date']) : dayjs()}
                minDate={
                  index > 0
                    ? dayjs(values.trip_stops[index - 1]['next_depature'])
                    : dayjs()
                }
                format="DD/MM/YYYY"
                onChange={(value) => {
                  setFieldValue('return_stop[date]', dayjs(value), true);
                  setDepatureDateTime(
                    dayjs(value).format('YYYY-MM-DD') +
                      ' ' +
                      dayjs(values.return_stop.time).format('HH:mm'),
                  );
                }}
                onBlur={handleBlur}
                value={dayjs(values.return_stop['date'])}
              />
            </LocalizationProvider>
          </DateSelect>
          <TimeSelect>
            <InputLabel htmlFor="return_stop_time">
              {t('PriceCalc.DepatureTime')}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                id="return_stop_time"
                name="return_stop[time]"
                error={Boolean(
                  touched.return_stop &&
                    touched.return_stop.time &&
                    errors.return_stop &&
                    errors.return_stop.time,
                )}
                slotProps={{
                  textField: {
                    helperText:
                      touched.return_stop &&
                      touched.return_stop.time &&
                      errors.return_stop &&
                      errors.return_stop.time,
                  },
                }}
                onChange={(value) => {
                  setFieldValue('return_stop[time]', dayjs(value), true);
                  setDepatureDateTime(
                    dayjs(values.return_stop.date).format('YYYY-MM-DD') +
                      ' ' +
                      dayjs(value).format('HH:mm'),
                  );
                }}
                // minTime={(index > 0 && dayjs(values.return_stop['date']).isSame(dayjs(values.trip_stops[index - 1]['date']), 'day')) ? dayjs(values.trip_stops[index - 1]['time']).add(1, 'hour') : false}
                minTime={
                  index > 0 &&
                  dayjs(values.return_stop['date']).isSame(
                    dayjs(values.trip_stops[index - 1]['next_depature']),
                    'day',
                  )
                    ? dayjs(values.trip_stops[index - 1]['next_depature'])
                    : false
                }
                onBlur={handleBlur}
                value={dayjs(values.return_stop['time'])}
                ampm={false}
                timeSteps={{ minutes: 15 }}
                thresholdToRenderTimeInASingleColumn={96}
              />
            </LocalizationProvider>
          </TimeSelect>
        </RightPart>
        <LeftPart></LeftPart>
        {values.return_stop['duration'] > 0 && (
          <RightPart>
            {/* <Stack>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>
							{`Duration : ${values.return_stop['duration']}`}
						</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>
							{`Duration Time : ${convertSecondsToHoursAndMinutesWithDayjs(values.return_stop['duration']).hours} hours and ${convertSecondsToHoursAndMinutesWithDayjs(values.return_stop['duration']).minutes} minutes`}
						</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>{'Arrival: '}{dayjs(values.return_stop['arrival']).format('YYYY-MM-DD HH:mm')}</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>{'Next Depature: '}{values.return_stop['next_depature']}</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>{t('PriceCalc.ExpectedArrival', { time: dayjs(values.return_stop['arrival']).format('HH:mm'), date: dayjs(values.return_stop['arrival']).format('DD/MM/YYYY') })}</Typography>
					</Stack> */}
            <Typography component="h4" sx={{ marginTop: '5px', fontSize: '12px', color:'#475569', backgroundColor:'#e0e7ff', padding:'0 4px', borderRadius:'4px' }}>
              {t('PriceCalc.ExpectedArrival', {
                time: dayjs(values.return_stop['arrival']).format('HH:mm'),
                date: dayjs(values.return_stop['arrival']).format('DD/MM/YYYY'),
              })}
            </Typography>
          </RightPart>
        )}
      </TripFeild>
    </TripBox>
  );
};

export default ReturnTripBoxComponent;
