import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 26px 0;
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
    padding-right: 10px;
  }
`;

export const RightPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  .MuiFormControl-root {
    @media screen and (max-width: 991px) {
      width: calc(50% - 15px);
    }
  }
  input {
    padding: 13px 16px 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  fieldset {
    border: 1px solid ${theme.colors.grey};
    border-radius: 8px;
  }
  form {
    display: flex;
    align-items: flex-end;
  }
  .MuiFormControl-root {
    margin: 0 15px 0 0;
    @media screen and (max-width: 991px) {
      margin: 7.5px 7.5px 15px;
    }
  }
  .MuiSelect-select {
    padding: 13px 32px 8px 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    position: relative;
    top: 3px;
  }
`;

export const DateSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  .MuiFormControl-root {
    @media screen and (max-width: 991px) {
      width: calc(100% - 15px) !important;
    }
  }
  .MuiTextField-root {
    input {
      padding-right: 0;
    }
    .MuiInputBase-root {
      flex-direction: row-reverse;
    }
    .MuiButtonBase-root {
      padding: 0;
      margin-top: 3px;
    }
  }
`;

export const DateBox = styled.div`
  width: 175px;
  @media screen and (max-width: 991px) {
    width: 50%;
  }
  label {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    margin: 0 0 7px 0;
    @media screen and (max-width: 991px) {
      margin: 0 0 0 8px;
    }
  }
  button {
    position: unset;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: transparent;
    }
  }
`;

export const TableContent = styled.div`
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  margin-bottom: 15px;
  table {
    thead {
      th {
        color: ${theme.colors.black};
        background-color: #f1f5f9;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        padding: 15px 17px 15px;
        border-bottom: 1px solid #e2e8f0;
      }
    }
    tbody {
      tr {
        &:first-of-type td {
          padding-top: 15px;
        }
        &:last-child td {
          padding-bottom: 15px;
        }
        td {
          padding: 15px 20px;
          text-align: left;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #1e293b input {
            padding: 10.5px 10px 7.5px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.3px;
            color: ${theme.colors.black};
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          fieldset {
            border: 1px solid ${theme.colors.black};
            border-radius: 6px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          svg {
            width: 26px;
            height: 26px;
            margin: 0 5px;
            cursor: pointer;
          }
          .MuiInputBase-formControl {
            font-size: 14px;
            letter-spacing: -0.28px;
            border-radius: 100px;
            &.partpaid {
              background: rgba(9, 105, 217, 0.18);
              fieldset {
                border: 1px solid #0969d9;
              }
              svg {
                fill: #0969d9;
              }
            }
            &.offered {
              background: rgba(238, 16, 189, 0.18);
              fieldset {
                border: 1px solid #ee10bd;
              }
              svg {
                fill: #ee10bd;
              }
            }
            &.accepted {
              background: rgba(241, 10, 10, 0.18);
              fieldset {
                border: 1px solid #f10a0a;
              }
              svg {
                fill: #f10a0a;
              }
            }
            &.paid {
              background: rgba(8, 170, 43, 0.18);
              fieldset {
                border: 1px solid #08aa2b;
              }
              svg {
                fill: #08aa2b;
              }
            }
            &.confirmed {
              background: rgba(245, 157, 25, 0.18);
              fieldset {
                border: 1px solid #f59d19;
              }
              svg {
                fill: #f59d19;
              }
            }
            &.created {
              background: #f7f8f9;
              fieldset {
                border: 1px solid #89878a;
              }
              svg {
                fill: #89878a;
              }
            }
            &.declined {
              color: #ffffff;
              background: #89878a;
              fieldset {
                border: 1px solid #89878a;
              }
              svg {
                fill: #ffffff;
              }
            }
            fieldset {
              box-shadow: none;
              border-radius: 100px;
              border: 1px solid #89878a;
            }
            svg {
              width: 31px;
              height: 31px;
              margin: -4px -6px 0 0;
              fill: #89878a;
            }
          }
          .MuiSelect-select {
            position: relative;
            top: 1px;
            text-align: left;
            padding: 3px 8px;
          }
          .detailLink {
            text-decoration-color: #6366f1;
            color: #6366f1;
            font-weight: 600;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.white}; // Sets the background color to white
    color: ${theme.colors.primary};    // Sets text color to the primary color from the theme
    border: 1px solid ${theme.colors.primary}; // Outline with primary color
    box-shadow: none; 
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    padding: 5px 5px 5px 10px;
    svg {
      width: 16px;
      height: 16px;
      margin-left: 10px;
      fill: #4f46e5;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  button {
    width: 191px;
    border-radius: 15px;
    background: ${theme.colors.black};
    box-shadow: none;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    padding: 12px 22px;
    min-height: 52px;
    padding: 0 0;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
    &.offer-btn {
      background: ${theme.colors.primary};
      svg {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
    }
    &.copy-btn {
      background: rgba(92, 91, 230, 0.24);
      color: ${theme.colors.black};
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
      svg {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.black};
    color: ${theme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    width: 126px;
    height: 27px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    padding: 2px 0 0;
    svg {
      width: 18px;
      height: 16px;
      margin-left: 10px;
      fill: #89878a;
    }
  }
  button.Mui-disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  p {
    text-align: center;
  }
`;
// Modal CSS
export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 732px;
  border-radius: 10px;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 22px 7px 22px;
  border-bottom: 1px solid ${theme.colors.grey};
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
  }
`;
export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 100px;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
export const ModalContentData = styled.div`
  padding: 24px;
  fieldset {
    border-color: ${theme.colors.darkGrey1};
  }
  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.darkGrey1};
      border-width: 1px;
    }
  }
  .MuiAutocomplete-tag {
    height: 28px;
    background-color: rgb(92 91 230 / 15%);
    border: 1px solid #5c5be6;
    span {
      line-height: 1;
      padding-top: 3px;
      padding-left: 6px;
      padding-right: 30px;
    }
    svg {
      path {
        fill: ${theme.colors.grey3};
      }
    }
  }
`;
