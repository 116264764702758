import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  padding-bottom: 20px;
  @media screen and (max-width: 1199px) {
    display: block;
    padding-bottom: 20px;
  }
`;
export const StatusField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  gap: 20px;
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiInputBase-formControl {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.28px;
    border-radius: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    &.paid {
      color: #166534;
      background: #86EFAC;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    &.partpaid {
      color: #1E40AF;
      background: #BFDBFE;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    &.offered {
      color: #9D174D;
      background: #FBCFE8;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    &.accepted {
      color: #C2410C;
      background: #FED7AA;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    &.confirmed {
      color: #92400E;
      background: #FEF08A;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    &.created {
      color: #374151;
      background: #E5E7EB;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    &.declined {
      color: #B91C1C;
      background: #FECACA;
      fieldset {
        border: 1px solid #475569;
      }
      svg {
        fill: #475569;
      }
    }
    fieldset {
      box-shadow: none;
      border-radius: 100px;
      border: 1px solid #89878a;
    }
    svg {
      width: 31px;
      height: 31px;
      margin: -4px -6px 0 0;
      fill: #89878a;
    }
  }
  .MuiSelect-select {
    position: relative;
    top: 1px;
    text-align: left;
    padding: 3px 8px;
  }
`;

export const StatusFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const BackButon = styled.div`
  margin-bottom: 25px;
  button.MuiButton-contained {
    border-radius: 26.5px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    background: ${theme.colors.darkGrey};
    color: ${theme.colors.black};
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
  }
`;
export const SwitchLanguage = styled.div`
  margin-bottom: 25px;
  .MuiOutlinedInput-root {
    width: 106px;
    height: 43px;
    border-radius: 26px;
    flex-direction: row-reverse;
    border: 1px solid ${theme.colors.black};
    svg {
      margin-left: 5px;
      width: 25px;
      height: 25px;
      &.flagIcon path {
        fill: #677489;
      }
    }
    .MuiSelect-select {
      padding: 0;
      display: flex;
      align-items: center;
      padding-right: 0 !important;
    }
    &:focus-visible {
      outline: none !important;
    }
    &:hover fieldset {
      border: none;
    }
    &.Mui-focused fieldset {
      border: none !important;
    }
  }
`;
export const EmailBlock = styled.div``;
export const ClientPart = styled.div`
  /*  width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; */
  .MuiInputBase-root {
    border-radius: 6px;
    width: 272px;
    .MuiInputBase-input {
      height: 48px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
    width: 100%;
  }
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

export const CopyButton = styled.div`
  height: 100%;
  button.MuiButton-contained {
    background: rgba(92, 91, 230, 0.24);
    color: ${theme.colors.black};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  button {
    width: 191px;
    border-radius: 15px;
    background: ${theme.colors.black};
    box-shadow: none;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    padding: 12px 22px;
    min-height: 52px;
    padding: 0 0;
    &.Mui-disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
    &.offer-btn {
      background: ${theme.colors.primary};
      svg {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
    }
    &.copy-btn {
      background: rgba(92, 91, 230, 0.24);
      color: ${theme.colors.black};
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
      svg {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
      &.Mui-disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
`;

export const Buttontab = styled.div`
  height: 100%;
  button.MuiButton-contained {
    background: ${theme.colors.grey1};
    color: ${theme.colors.grey3};
  }
  &.active {
    button.MuiButton-contained {
      background: ${theme.colors.orange};
      color: ${theme.colors.white};
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const TagButton = styled.div`
  padding: 0 12px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid ${theme.colors.primary};
  background: rgba(92, 91, 230, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: default;
  p.MuiBox-root {
    margin-left: 8px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    color: ${theme.colors.black};
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    top: 1px;
  }
    svg {
    color: #475569;
    font-size: 18px;
  }
`;
export const OnlineQuoteButton = styled.a`
  padding: 0 12px;
  text-decoration: none;
  height: 30px;
  border-radius: 100px;
  border: 1px solid #475569;
  background: #c7d2fe;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  p.MuiBox-root {
    margin-right: 8px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    color: ${theme.colors.black};
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    top: 1px;
  }
`;
export const ShareButton = styled.div`
  button.MuiButton-contained {
    background: ${theme.colors.primary};
  }
`;
export const ShareButtons = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    width: 126px;
    height: 27px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    padding: 3px 0 0;
    svg {
      width: 18px;
      height: 16px;
      margin-left: 10px;
      fill: #89878a;
    }
  }
  button.Mui-disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  p {
    text-align: center;
  }
`;
// Modal CSS
export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 732px;
  border-radius: 10px;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 22px 7px 22px;
  border-bottom: 1px solid ${theme.colors.grey};
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
  }
`;
export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 100px;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
export const ModalContentData = styled.div`
  padding: 24px;
  fieldset {
    border-color: ${theme.colors.darkGrey1};
  }
  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.darkGrey1};
      border-width: 1px;
    }
  }
  .MuiAutocomplete-tag {
    height: 28px;
    background-color: rgb(92 91 230 / 15%);
    border: 1px solid #5c5be6;
    span {
      line-height: 1;
      padding-top: 3px;
      padding-left: 6px;
      padding-right: 30px;
    }
    svg {
      path {
        fill: ${theme.colors.grey3};
      }
    }
  }
`;
export const OfferForm = styled.div`
  h6 {
    font-weight: bold;
    font-size: 16px;
    margin-top: 1em;
  }
`;
