import React from 'react';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import ProfileDetailsComponent from '../../components/ProfileDetailsComponent/index.js';
//import PaymentInformationComponent from '../../components/PaymentInformationComponent/index.js';
import DepotsListComponent from '../../components/DepotsListComponent/index.js';
import EmailPersonalization from '../../components/CustomEmailComponent/index.js';
import StripeAccount from '../../components/StripeAccount/index.js';
const ProfiloPage = () => {
  const { userDetails } = useSelector((state) => state.auth);
  return (
    <>
      <Container maxWidth="lg">
        <ProfileDetailsComponent />
        {/*
				<PaymentInformationComponent />
				*/}
        <StripeAccount />
        <DepotsListComponent />
        {userDetails?.role == 'Admin' && <EmailPersonalization language="IT" />}
        {userDetails?.role == 'Admin' && <EmailPersonalization language="EN" />}
      </Container>
    </>
  );
};

export default ProfiloPage;
