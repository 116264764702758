import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import {
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  Button,
  Container,
  Snackbar,
  Alert,
  CircularProgress,
  Stack,
} from '@mui/material';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TripBoxComponent from '../../components/TripBoxComponent';
import ReturnTripBoxComponent from '../../components/TripBoxComponent/return';
import PriceQuotaComponent from '../../components/PriceQuotaComponent';
import { convertTripData } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { postPriceCalculation } from '../../redux/pricecalc';
import {
  resetArrivalTime,
  resetArrivalTimeReturn,
  resetPriceCalculation,
} from '../../redux/pricecalc/slice';
import {
  HeadPart,
  TripInfo,
  AddStopButton,
  BottomInfo,
  BottomContentLeft,
  BottomContentRight,
  BottomInput,
  MainTripBox,
  CheckBoxSelect,
  CustomDivider,
} from './styled';
import { Persist } from 'formik-persist';
import { resetReqHistoryInfo } from '../../redux/reqhistory/slice';
import { getHistoryDetail } from '../../redux/reqhistory';

const PriceCalculationPage = () => {
  const [currentTab, setCurrentTab] = useState('agent');
  const { updateReqLanguageDetail } = useSelector((state) => state.reqhistory);
  const [divCount, setDivCount] = useState(1);
  const [failOpen, setFailOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const { loading, priceCalculationDetail, priceCalculationError } =
    useSelector((state) => state.pricecalc);
  const { userDetails } = useSelector((state) => state.auth);
  const priceDetailRef = useRef();
  const dispatch = useDispatch();
  const formikRef = useRef();

  useEffect(() => {
    return () => {
      dispatch(resetPriceCalculation());
      dispatch(resetReqHistoryInfo());
    };
  }, []);

  useEffect(() => {
    if (priceCalculationError.error) {
      let errors = [];
      if (typeof priceCalculationError.message === 'string') {
        errors.push(priceCalculationError.message);
      } else {
        for (let key in priceCalculationError.message) {
          if (key.includes('trip_stops')) {
            for (let innerKey in priceCalculationError.message[key]) {
              for (let errorKey in priceCalculationError.message[key][
                innerKey
              ]) {
                errors.push(
                  `Sequence: ${parseInt(innerKey) + 1} has error for ${errorKey}: ${priceCalculationError.message[key][innerKey][errorKey]}`,
                );
              }
            }
          } else if (key.includes('error')) {
            for (let msg in priceCalculationError.message.error) {
              errors.push(priceCalculationError.message.error[msg]);
            }
          }
        }
      }
      setErrorMessages(errors);
      setFailOpen(true);
    }
  }, [priceCalculationError]);

  useEffect(() => {
    if (!isEmpty(priceCalculationDetail)) {
      priceDetailRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [priceCalculationDetail]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFailOpen(false);
  };
  const addStop = (values, setFieldValue, insertAt) => {
    const newStop = {
      sequence: divCount + 1,
      date: !isEmpty(values.trip_stops[insertAt - 1]['next_depature'])
        ? dayjs(values.trip_stops[insertAt - 1]['next_depature'])
        : dayjs(values.trip_stops[insertAt - 1]['date']),
      time: !isEmpty(values.trip_stops[insertAt - 1]['next_depature'])
        ? dayjs(values.trip_stops[insertAt - 1]['next_depature'])
        : dayjs(values.trip_stops[insertAt - 1]['time']).add(1, 'hour'),
      start: { ...values.trip_stops[insertAt - 1].end },
      end: {
        lat: '',
        lon: '',
        address: '',
        post_code: '',
        city: '',
        country: '',
        region: '',
      },
      arrival: '',
      next_depature: '',
      duration: 0,
    };
    const updatedStops = [
      ...values.trip_stops.slice(0, insertAt),
      newStop,
      ...values.trip_stops.slice(insertAt),
    ];
    setFieldValue('trip_stops', updatedStops);
    setFieldValue('return_stop.start', {
      lat: '',
      lon: '',
      address: '',
      post_code: '',
      city: '',
      country: '',
      region: '',
    });
    setFieldValue('return_stop.arrival', '');
    setFieldValue('return_stop.next_depature', '');
    setFieldValue('return_stop.duration', 0);
    setFieldValue(
      'return_stop.time',
      dayjs(values.return_stop.time).add(15, 'minutes'),
    );
    // setFieldValue('trip_stops', [...values.trip_stops, newStop]);
    setDivCount(values.trip_stops.length + 1);
  };
  const addReturn = (values, setFieldValue) => {
    const newStop = {
      sequence: values.trip_stops.length + 1,
      date: !isEmpty(values.trip_stops[divCount - 1]['next_depature'])
        ? dayjs(values.trip_stops[divCount - 1]['next_depature'])
        : dayjs(values.trip_stops[divCount - 1]['date']),
      time: !isEmpty(values.trip_stops[divCount - 1]['next_depature'])
        ? dayjs(values.trip_stops[divCount - 1]['next_depature'])
        : dayjs(values.trip_stops[divCount - 1]['time']).add(1, 'hour'),
      start: values.trip_stops[divCount - 1].end,
      end: values.trip_stops[0].start,
    };
    setFieldValue('return_stop', newStop);
  };
  const handleDeleteStop = (values, setFieldValue, index) => {
    dispatch(resetArrivalTime());
    const updatedStops = [...values.trip_stops];
    // If the element to be deleted is the last one, store its end object
    if (index === updatedStops.length - 1 && values.return_flag) {
      const newReturn = {
        ...values.return_stop,
        start: updatedStops[index - 1].end,
      };
      setFieldValue('return_stop', newReturn, true);
    }
    // Delete Element from Array
    updatedStops.splice(index, 1);
    if (updatedStops.length > index) {
      //Assign Delete Previous end address to new index start.
      updatedStops[index].start = updatedStops[index - 1].end;
    }
    // Update sequence values
    const newUpdatedStops = updatedStops.map((item, i) => {
      return { ...item, sequence: i + 1 };
    });
    setFieldValue('trip_stops', newUpdatedStops);
    setDivCount(values.trip_stops.length - 1);
  };
  const initialValues = {
    return_flag: 1,
    bus_on_site_flag: 0,
    passenger_number: 49,
    is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
    trip_stops: [
      {
        sequence: 1,
        date: dayjs().add(4, 'weeks'),
        time: dayjs().add(4, 'weeks').set('hour', 9).startOf('hour'),
        start: {
          lat: '',
          lon: '',
          address: '',
          post_code: '',
          city: '',
          country: '',
          region: '',
        },
        end: {
          lat: '',
          lon: '',
          address: '',
          post_code: '',
          city: '',
          country: '',
          region: '',
        },
        arrival: '',
        next_depature: '',
        duration: 0,
      },
    ],
    return_stop: {
      sequence: 2,
      date: dayjs().add(4, 'weeks'),
      time: dayjs()
        .add(4, 'weeks')
        .set('hour', 9)
        .startOf('hour')
        .add(1, 'hour'),
      start: '',
      end: '',
      arrival: '',
      next_depature: '',
      duration: 0,
    },
  };
  const validationSchema = Yup.object().shape({
    passenger_number: Yup.number()
      .required('Required')
      .min(8, t('General.Min', { min: 8 }))
      .max(900, t('General.Max', { max: 900 })),
    //Temp Commented
    trip_stops: Yup.array()
      .of(
        Yup.object().shape({
          sequence: Yup.number().required('Required'),
          date: Yup.string().required('Required'),
          time: Yup.string().required('Required'),
          start: Yup.object().shape({
            lat: Yup.string().required(
              t('PriceCalc.Validation.DepatureNotValid'),
            ),
            lon: Yup.string().required(
              t('PriceCalc.Validation.DepatureNotValid'),
            ),
            address: Yup.string().nullable(),
            post_code: Yup.string().nullable(),
            city: Yup.string().nullable(),
            country: Yup.string().nullable(),
            region: Yup.string().nullable(),
          }),
          end: Yup.object().shape({
            lat: Yup.string().required(
              t('PriceCalc.Validation.DestinationNotValid'),
            ),
            lon: Yup.string().required(
              t('PriceCalc.Validation.DestinationNotValid'),
            ),
            address: Yup.string().nullable(),
            post_code: Yup.string().nullable(),
            city: Yup.string().nullable(),
            country: Yup.string().nullable(),
            region: Yup.string().nullable(),
          }),
        }),
      )
      .required('Must have trip stops'),
  });
  const handleUpdateConfig = (values) => {
    handleClose();
    const payload = { ...values };
    if (payload.return_flag) {
      payload.trip_stops = [...payload.trip_stops, payload.return_stop];
    }
    delete payload?.return_stop;
    payload.trip_stops = convertTripData(payload.trip_stops);
    dispatch(postPriceCalculation(payload));
  };
  const handleReset = () => {
    dispatch(resetArrivalTime());
    dispatch(resetArrivalTimeReturn());
    formikRef.current.resetForm();
    formikRef.current.setValues(initialValues);
    setDivCount(1);
  };
  useEffect(() => {
    if (!isEmpty(updateReqLanguageDetail)) {
      const payload = {
        requestId: priceCalculationDetail?.trip_code,
        is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
      };
      dispatch(getHistoryDetail(payload));
    }
  }, [
    dispatch,
    priceCalculationDetail?.trip_code,
    updateReqLanguageDetail,
    userDetails?.role,
  ]);
  return (
    <>
      <Container maxWidth="lg">
        {currentTab === 'agent' && (
          <HeadPart>
            <Typography variant="h2">{t('PriceCalc.Title')}</Typography>
            <Button color="grey" variant="contained" onClick={handleReset}>
              <RestartAltOutlinedIcon />
              {t('PriceCalc.Reset')}
            </Button>
          </HeadPart>
        )}
        {currentTab === 'agent' && (
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdateConfig}>
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleChange,
              handleSubmit,
              handleBlur,
            }) => (
              <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                <Stack direction="row" spacing={2}>
                  <CheckBoxSelect>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <input
                            name="return_flag"
                            type="radio"
                            value="1"
                            checked={values.return_flag == 1}
                            onChange={(e) => {
                              handleChange(e);
                              const updatedValue = e.target.value;
                              if (updatedValue == 1) {
                                addReturn(values, setFieldValue);
                              } else {
                                setFieldValue('return_stop', {});
                              }
                              setFieldValue(
                                'return_flag',
                                parseInt(updatedValue),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        }
                        label={t('PriceCalc.RoundTrip')}
                      />
                    </FormGroup>
                  </CheckBoxSelect>
                  <CheckBoxSelect>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <input
                            name="return_flag"
                            type="radio"
                            value="0"
                            checked={values.return_flag == 0}
                            onChange={(e) => {
                              handleChange(e);
                              const updatedValue = e.target.value;
                              if (updatedValue == 1) {
                                addReturn(values, setFieldValue);
                              } else {
                                setFieldValue('return_stop', {});
                              }
                              setFieldValue(
                                'return_flag',
                                parseInt(updatedValue),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        }
                        label={t('PriceCalc.OneWay')}
                      />
                    </FormGroup>
                  </CheckBoxSelect>
                </Stack>
                <TripInfo>
                  {values.trip_stops.map((_, index) => (
                    <MainTripBox key={index}>
                      <TripBoxComponent
                        index={index}
                        deleteStop={() =>
                          handleDeleteStop(values, setFieldValue, index)
                        }
                      />
                      <AddStopButton className="button">
                        <Button
                          variant="contained"
                          startIcon={<AddOutlinedIcon />}
                          onClick={() =>
                            addStop(values, setFieldValue, index + 1)
                          }>
                          {t('PriceCalc.AddStop')}
                        </Button>
                      </AddStopButton>
                    </MainTripBox>
                  ))}
                  {values.return_flag == 1 && divCount > 0 && values && (
                    <ReturnTripBoxComponent
                      index={values.trip_stops.length}
                      returnBlock={true}
                    />
                  )}
                </TripInfo>
                <BottomInfo>
                  <BottomContentLeft>
                    <CheckBoxSelect>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <input
                              name="bus_on_site_flag"
                              type="checkbox"
                              value="1"
                              checked={values.bus_on_site_flag === 1}
                              onChange={(e) => {
                                handleChange(e);
                                const updatedValue = e.target.checked ? 1 : 0;
                                handleChange({
                                  target: {
                                    name: 'bus_on_site_flag',
                                    value: updatedValue,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                            />
                          }
                          label={t('PriceCalc.BusOnSite')}
                        />
                      </FormGroup>
                    </CheckBoxSelect>
                    <BottomInput>
                      <InputLabel htmlFor="passenger_number">
                        {t('PriceCalc.Passengers')}
                      </InputLabel>
                      <TextField
                        error={
                          touched.passenger_number && errors.passenger_number
                        }
                        helperText={
                          touched.passenger_number && errors.passenger_number
                        }
                        inputProps={{ min: 8, max: 900 }}
                        type="number"
                        id="passenger_number"
                        name="passenger_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.passenger_number}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PeopleAltOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </BottomInput>
                  </BottomContentLeft>
                  <BottomContentRight>
                    {loading && (
                      <CircularProgress
                        color="warning"
                        size={24}
                        thickness={5}
                      />
                    )}
                    <Button type="submit" variant="contained">
                      {t('PriceCalc.CalculatePrice')}
                      <ArrowCircleDownOutlinedIcon/>
                    </Button>
                  </BottomContentRight>
                </BottomInfo>
                <Persist name="trip-calc" />
              </Form>
            )}
          </Formik>
        )}
        {!isEmpty(priceCalculationDetail) && (
          <div ref={priceDetailRef}>
            {currentTab === 'agent' && (
              <CustomDivider style={{ marginTop: '60px' }}></CustomDivider>
            )}
            <PriceQuotaComponent
              priceCalculationDetail={priceCalculationDetail}
              detailPage={false}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={failOpen}
          onClose={handleClose}>
          {
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}>
              {!isEmpty(errorMessages) &&
                errorMessages.map((error, index) => (
                  <Typography key={index}>{error}</Typography>
                ))}
            </Alert>
          }
        </Snackbar>
      </Container>
    </>
  );
};

export default PriceCalculationPage;
