import { Paper, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const PaperContainer = styled(Paper)({
  padding: '30px',
  borderRadius: '10px',
  backgroundColor: '#eef2ff',
  margin: '15px',
  border: '1px solid #a5b4fc'
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
});

export const Badge = styled(Typography)(({ theme }) => ({
  display: 'flex', // Ensures content is in a row
  alignItems: 'center', // Centers items vertically
  fontSize: '14px',
  fontWeight: '600',
  marginRight: '8px',
  gap: '4px',
  backgroundColor: '#c7d2fe',
  padding: '6px 8px',
  borderRadius: '24px',
  color: '#4f46e5',
}));

export const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '20px',
  marginTop: '15px',
  lineHeight: '1.35rem',
});

export const Description = styled(Typography)({
  margin: '5px 0',
  fontSize: '16px',
  color: '#1e293b',
  lineHeight: '1.25rem',
});
