import React, { useEffect, useState } from 'react';
import { Collapse, Stack, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { StepNumber, StepTitle, StepContent, AccordionButton, AccordionTitle } from './styled.js';

const StepBlock = ({ stepTitle, isOpen = false, children }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
    return () => {
      setOpen(false);
    };
  }, [isOpen]);

  return (
    <StepNumber>
      <StepTitle onClick={() => setOpen((state) => !state)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <AccordionTitle variant="h3">{stepTitle}</AccordionTitle>
          <AccordionButton>
            {open ? <ExpandLess /> : <ExpandMore />}
          </AccordionButton>
        </Stack>
      </StepTitle>
      <Collapse in={open}>
        <StepContent>{children}</StepContent>
      </Collapse>
    </StepNumber>
  );
};

export default StepBlock;
