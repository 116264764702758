import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const Header = styled.div`
  display: flex; /* Makes it a flex container */
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  padding: 15px 30px;

  ${theme.breakPoint.tablet} {
    padding: 10px 20px;
  }

  svg {
    width: 180px;
    ${theme.breakPoint.tablet} {
      width: 140px;
    }
  }
`;
