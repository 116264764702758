import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  margin-top: 7px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
  }
`;
export const CustomSwitch = styled.div`
  // background-color:red;
  width: 83px;
  height: 31.03px;
  padding: 0;
  margin-right: 30px;
  border-radius: 50px;
  .MuiSwitch-switchBase {
    padding: 0;
    margin: 3px;
    transition-duration: 300ms;
  }
  .MuiSwitch-root {
    padding: 0;
    height: 100%;
    width: 100%;
  }
  .Mui-checked {
    transform: translateX(47px);
    color: #fff;
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(47px);
    color: #fff;
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${theme.colors.lightGreen};
    opacity: 1;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.1) inset;
    border: 0;
  }

  .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track {
    opacity: 0.5;
  }

  .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb {
    color: #33cf4d;
    border: 6px solid #fff;
  }

  .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb {
    color: #e9e9ea;
  }

  .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 0.7;
  }

  .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 29.05px;
    height: 24.13px;
  }

  .MuiSwitch-root {
    overflow: inherit;
  }

  .MuiSwitch-track {
    border-radius: 15.5px;
    background-color: #e9e9ea;
    opacity: 1;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.1) inset;
    transition: background-color 500ms;
  }
`;

export const StripBox = styled.div`
  border-radius: 10px;
  border: 1px solid #cbd5e1;
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
  }
  text-align: left;
  h6 {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: ${theme.colors.black};
    margin: 0 0 15px 0;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
`;
export const PaymentInfoBox = styled.div`
  border-radius: 10px;
  border: 1px solid #cbd5e1;
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
  }
  .MuiGrid-item {
    margin: 0 0 0 0;
    padding-left: 26px;
  }
  h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${theme.colors.black};
    margin: 0 0 15px 0;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${theme.colors.black};
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  a {
    color: ${theme.colors.black};
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      color: ${theme.colors.primary};
    }
  }
  a.busLink {
    color: ${theme.colors.primary};
    transition: 0.3s all;
    text-decoration: underline;
    font-weight: 700;
  }
`;
export const EnableStripeButton = styled.a`
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
  button.MuiButton-contained {
    border-radius: 10px;
    background: ${theme.colors.primary};
    box-shadow: none;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
  }
`;
export const CompleteButton = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
  button.MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  button.MuiButton-contained {
    border-radius: 10px;
    background: ${theme.colors.primary};
    box-shadow: none;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    margin: 0;
    line-height: 30px;
  }
`;
// Modal CSS

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 732px;
  border-radius: 10px;
  background: ${theme.colors.white};
  box-shadow: none;
  outline: none;
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.grey};
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
  }
`;
export const CloseButton = styled.div`
  
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const ModalContentData = styled.div`
  padding: 34px 22px;
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.colors.black};
    letter-spacing: -0.02em;
  }
`;
export const GreenButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 20px;
  button.MuiButton-contained {
    border-radius: 10px;
    background: ${theme.colors.primary};
    box-shadow: none;
    padding: 15px 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-transform: none;
    margin-right: 20px;
  }
`;
export const CurrentDue = styled.p`
  font-size: 15px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 14px;
  max-width: 250px;
`;
