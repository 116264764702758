import React from 'react';
import { PaperContainer, Header, Badge, Title, Description } from './styled';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'; // Step 1: Import the icon

const NextBestActionComponent = ({ status }) => {
  let title, description;

  switch (status) {
    case 'created':
      title = "Prepara e invia il preventivo";
      description = "Utilizza il preventivatore per preparare e inviare una proposta dettagliata al cliente con tutte le informazioni necessarie per il viaggio.";
      break;
    case 'offered':
      title = "In attesa della conferma del cliente";
      description = "Hai inviato il preventivo. Attendi la conferma del cliente e rimani disponibile per eventuali domande.";
      break;
    case 'accepted':
      title = "Il cliente ha accettato, conferma disponibilità e invia dettagli di pagamento";
      description = "Contattalo via chat per confermare la disponibilità e fornire i dettagli per il pagamento.";
      break;
    case 'confirmed':
      title = "Richiedi il Pagamento";
      description = "La prenotazione è confermata. Usa la chat per invitare il cliente a procedere con il pagamento.";
      break;
    case 'partpaid':
      title = "Richiedi il Saldo Completo";
      description = "Hai ricevuto un pagamento parziale. Usa la chat per richiedere al cliente di completare il pagamento.";
      break;
    case 'paid':
      title = "Organizza il Viaggio";
      description = "Il pagamento è stato ricevuto! Prepara la tua flotta e invia al cliente le informazioni finali tramite chat.";
      break;
    default:
      return null; // No component for other statuses
  }

  return (
    <PaperContainer elevation={0}>
      <Header>
        <Badge>
          <OfflineBoltOutlinedIcon /> {/* Step 2: Include the icon in the Badge */}
          Azioni Consigliate
        </Badge>
      </Header>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </PaperContainer>
  );
};

export default NextBestActionComponent;
