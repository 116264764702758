import styled from '@emotion/styled';
import theme from '../../themes/base.json';

// Modal CSS
export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 20px 15px 20px;
  border-bottom: 1px solid ${theme.colors.grey};
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
    width: calc(100% - 33px);
  }
`;
export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 100px;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
export const ModalContentData = styled.div`
  padding: 34px 22px;
  text-align: left;
  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin: 0 0 15px 0;
    color: ${theme.colors.black};
    letter-spacing: -0.4px;
  }
  p {
    margin-bottom: 15px;
  }
  a {
    color: ${theme.colors.blue};
    text-decoration: none;
  }
  span.MuiTypography-body1 {
    margin-top: 7px;
  }
  .MuiFormGroup-root {
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-top: 10px;
    }
    .Mui-checked {
      color: ${theme.colors.primary};
    }
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  button.MuiButton-contained {
    border-radius: 6px;
    background: ${theme.colors.black}; // background for primary action buttons
    padding: 12px 20px; // balanced padding
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: none;
    min-width: 120px; // slightly larger width
    margin: 0 10px;
    color: ${theme.colors.white}; // white text for better contrast
    border: none; // no border for filled buttons
    box-shadow: none; // shadow removed
    &:focus {
      outline: none;
      box-shadow: none;
      border: 2px solid ${theme.colors.blue}; // focus indicator
    }
  }
`;

export const RedButton = styled(ModalButtons)`
  button.MuiButton-contained {
    background: ${theme.colors.red};
  }
`;

export const GreyButton = styled(ModalButtons)`
  button.MuiButton-contained {
    background: transparent; // transparent background for an outline style
    color: ${theme.colors.black}; // dark text for visibility
    border: 1px solid ${theme.colors.darkGrey}; // outlined with darker grey
    &:focus {
      border-color: ${theme.colors.blue}; // blue border for focus
      color: ${theme.colors.black};
    }
  }
`;

export const GreenButton = styled(ModalButtons)`
  button.MuiButton-contained {
    background: ${theme.colors.green};
    color: ${theme.colors.white};
    &:hover {
      background: ${theme.colors
        .darkGreen}; // Optional: Darken on hover when enabled
    }
    &:disabled {
      background: ${theme.colors
        .grey}; // Change to a grey or another muted color when disabled
      color: ${theme.colors
        .lightGrey}; // Lighter text to indicate it's disabled
      cursor: not-allowed; // Change cursor to indicate the button is not interactive
    }
  }
`;

export const AddBusField = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 21px 0;
  &:nth-last-of-type(2) {
    margin-bottom: 0;
  }
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    color: ${theme.colors.black};
    width: 200px;
    text-align: left;
  }
  .MuiSelect-select {
    padding: 9.5px 40px 5.5px 15px;
  }
  input {
    padding: 9.5px 15px 5.5px;
    text-align: center;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 14px;
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
`;

// End Modal CSS
